"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _objectSpread = require("@swc/helpers/lib/_object_spread.js").default;
var _objectSpreadProps = require("@swc/helpers/lib/_object_spread_props.js").default;
var tailwindColors = require("tailwindcss/colors");
var amber = tailwindColors.amber, black = tailwindColors.black, blue = tailwindColors.blue, current = tailwindColors.current, cyan = tailwindColors.cyan, emerald = tailwindColors.emerald, fuchsia = tailwindColors.fuchsia, green = tailwindColors.green, indigo = tailwindColors.indigo, inherit = tailwindColors.inherit, lime = tailwindColors.lime, neutral = tailwindColors.neutral, orange = tailwindColors.orange, pink = tailwindColors.pink, purple = tailwindColors.purple, red = tailwindColors.red, rose = tailwindColors.rose, sky = tailwindColors.sky, slate = tailwindColors.slate, stone = tailwindColors.stone, teal = tailwindColors.teal, transparent = tailwindColors.transparent, violet = tailwindColors.violet, white = tailwindColors.white, yellow = tailwindColors.yellow, zinc = tailwindColors.zinc;
/** @type {import('tailwindcss/colors')} */ var colors = {
    amber: amber,
    black: black,
    blue: blue,
    current: current,
    cyan: cyan,
    emerald: emerald,
    fuchsia: fuchsia,
    green: green,
    indigo: indigo,
    inherit: inherit,
    lime: lime,
    neutral: neutral,
    orange: orange,
    pink: pink,
    purple: purple,
    red: red,
    rose: rose,
    sky: sky,
    slate: slate,
    stone: stone,
    teal: teal,
    transparent: transparent,
    violet: violet,
    white: white,
    yellow: yellow,
    zinc: zinc,
    gray: _objectSpreadProps(_objectSpread({}, tailwindColors.neutral), {
        // 700: tailwindColors.neutral['800'],
        // discord colors
        600: "#42474D",
        700: "#36393F",
        800: "#2F3136",
        900: "#212225"
    })
};
module.exports = colors;
