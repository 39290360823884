import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { useTheme } from "next-themes";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import React, { useState, useEffect, createContext, useContext } from "react";
import toast from "react-hot-toast";
import colors from "../colors";
import { Faded } from "baby-i-am-faded";
import router from "next/router";
Faded.defaultProps = {
    cascadeIncrement: 80,
    duration: 120
};
export function useDisclosure() {
    var defaultIsOpen = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    var ref = useState(defaultIsOpen), isOpen = ref[0], setIsOpen = ref[1];
    var toggle = function() {
        return setIsOpen(!isOpen);
    };
    return {
        isOpen: isOpen,
        toggle: toggle,
        onClose: function onClose() {
            setIsOpen(false);
        },
        onOpen: function onOpen() {
            setIsOpen(true);
        }
    };
}
// Hook
export function useOnClickOutside(ref, handler) {
    useEffect(function() {
        var listener = function(event) {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return function() {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [
        ref,
        handler
    ]);
}
// show toasts on success and failure and manages loading state
// you can skip showing the toast on failure putting a field skipToast: true in the error
export function useThrowingFn(param) {
    var fnToWrap = param.fn, _successMessage = param.successMessage, successMessage = _successMessage === void 0 ? "" : _successMessage, _errorMessage = param.errorMessage, errorMessage = _errorMessage === void 0 ? "Error" : _errorMessage;
    var ref = _sliced_to_array(React.useState(false), 2), isLoading = ref[0], setIsLoading = ref[1];
    var fn = function() {
        var _wrappedThrowingFn = _async_to_generator(function() {
            var _len, args, _key, result, err;
            var _arguments = arguments;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        for(_len = _arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = _arguments[_key];
                        }
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            3,
                            4,
                            5
                        ]);
                        setIsLoading(true);
                        return [
                            4,
                            fnToWrap.apply(void 0, _to_consumable_array(args))
                        ];
                    case 2:
                        result = _state.sent();
                        if (result === null || result === void 0 ? void 0 : result.skipToast) {
                            return [
                                2,
                                result
                            ];
                        }
                        if (successMessage) {
                            toast.success(successMessage);
                        }
                        return [
                            2,
                            result
                        ];
                    case 3:
                        err = _state.sent();
                        console.error(err);
                        // how to handle unreadable errors? simply don't return them from APIs, just return something went wrong
                        if (_instanceof(err, Error) && !(err === null || err === void 0 ? void 0 : err["skipToast"])) {
                            toast.error(err.message, {});
                            return [
                                2,
                                err
                            ];
                        }
                        return [
                            2,
                            err
                        ];
                    case 4:
                        setIsLoading(false);
                        return [
                            7
                        ];
                    case 5:
                        return [
                            2
                        ];
                }
            });
        });
        function wrappedThrowingFn() {
            return _wrappedThrowingFn.apply(this, arguments);
        }
        return wrappedThrowingFn;
    }();
    return {
        isLoading: isLoading,
        fn: fn
    };
}
export function useColorMode() {
    var initialColorMode = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "dark";
    var toggleColorMode = function toggleColorMode() {
        setTheme(resolvedTheme === "dark" ? "light" : "dark");
    };
    var ref = useTheme(), _resolvedTheme = ref.resolvedTheme, setTheme = ref.setTheme;
    var ref1 = useState(function() {
        return initialColorMode;
    }), resolvedTheme = ref1[0], setResolvedTheme = ref1[1];
    useEffect(function() {
        setResolvedTheme(_resolvedTheme);
    }, [
        _resolvedTheme
    ]);
    return {
        toggleColorMode: toggleColorMode,
        colorMode: resolvedTheme,
        isDark: resolvedTheme === "dark"
    };
}
export function useColorModeValue(a, b) {
    var isDark = useColorMode().isDark;
    return isDark ? b : a;
}
export function useBeskar() {
    var data = useContext(beskarContext);
    if (!data) {
        throw new Error("useBeskar must be used within a beskarContext");
    }
    return data;
}
export var beskarContext = createContext({});
export function getColor(color) {
    var c = dotsGet(color, colors) || color;
    return c;
}
function dotsGet(accessor, obj) {
    return accessor.split(".").filter(Boolean).reduce(function(acc, cur) {
        return acc[cur];
    }, obj);
}
export var useSafeLayoutEffect = "object" !== "undefined" ? React.useLayoutEffect : React.useEffect;
export function rateLimit(param) {
    var _max = param.max, max = _max === void 0 ? 100 : _max, interval = param.interval, fn = param.fn;
    var bucket = getTimeBucket(interval);
    var count = 0;
    var func = function() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        var currentBucket = getTimeBucket(interval);
        if (currentBucket !== bucket) {
            count = 0;
            bucket = currentBucket;
        }
        count++;
        if (count > max) {
            throw new Error("Too many function calls in 1 hour: ".concat(max));
        }
        return fn.apply(void 0, _to_consumable_array(args));
    };
    return func;
}
function getTimeBucket(kind) {
    var now = new Date();
    if (kind === "day") {
        now.setHours(0);
    }
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now.getTime();
}
export function maxWidthWithPx() {
    var px = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "40px";
    return "min(var(--page-max-width, 1200px), calc(100vw - ".concat(px, "))");
}
export function refreshSsr() {
    var ref;
    return router.replace({
        pathname: router.pathname,
        query: _object_spread_props(_object_spread({}, router.query), {
            remount: (Number(((ref = router.query) === null || ref === void 0 ? void 0 : ref.remount) || 0) || 0) + 1
        })
    }, undefined, {
        scroll: false,
        shallow: false,
        unstable_skipClientCache: true
    });
}
export function formatBigNumber(n) {
    var formatter = new Intl.NumberFormat("en-US", {
        compactDisplay: "short",
        notation: "compact"
    });
    return formatter.format(n);
}
