import { env } from '@app/env'
import { trackUserSignUpReason } from '@app/pages/api/functions'
import splitbee from '@splitbee/web'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { notifyError } from './sentry'

const redditStandardEvents = [
    'SignUp',
    'AddToCart',
    'Lead',
    'Purchase',
] as const
const facebookStandardEvents = [
    'Lead',
    'Purchase', // {currency: "USD", value: 30.00}
    'AddToCart',
    'InitiateCheckout',
    'StartTrial',
] as const

declare global {
    const gtag: Function
    const rdt: Function
    const fbq: Function
    const Reflio: any
}

function conversionFunction(
    {
        gtagId = '',
        redditId = '',
        facebook = '' as typeof facebookStandardEvents[number],
        splitbee: splitbeeId = '',
        crispId = '',
    },
    params = {},
): Function {
    return (otherParams = {}) => {
        console.log('conversion', { gtagId, redditId, splitbeeId })
        if (process.env.NODE_ENV !== 'production') {
            return
        }
        if (gtagId) {
            try {
                gtag('event', 'conversion', {
                    send_to: gtagId,
                    ...params,
                    ...otherParams,
                })
            } catch {
                console.warn(`Could not track google conversion`)
            }
        }
        if (facebook) {
            try {
                fbq('track', facebook, { ...params, ...otherParams })
            } catch {
                console.warn(`Could not track facebook conversion`)
            }
        }
        if (redditId) {
            const part = redditStandardEvents.includes(redditId as any)
                ? [redditId, { ...params, ...otherParams }]
                : [
                      'Custom',
                      { customEventName: redditId, ...params, ...otherParams },
                  ]
            try {
                rdt('track', ...part)
            } catch {
                console.warn(`Could not track reddit conversion`)
            }
        }
        if (splitbeeId) {
            try {
                splitbee.track(splitbeeId, { ...params, ...otherParams })
            } catch {
                console.warn(`Could not track splitbee conversion`)
            }
        }
        if (crispId) {
            try {
                $crisp.push([
                    'set',
                    'session:event',
                    [[[crispId, { ...params, ...otherParams }]]],
                ])
            } catch {
                console.warn(`Could not track crisp conversion`)
            }
        }
    }
}

export const conversionSignUp = conversionFunction({
    splitbee: 'signup',
    // facebook: 'Lead',
})

export const signupConversionLocalStorageKey = 'signupConversion'

export function useSignupConversion() {
    const { data: session } = useSession()

    const router = useRouter()

    useEffect(() => {
        const signupReason = router.query.signupReason
        const sentSignup = localStorage.getItem(signupConversionLocalStorageKey)
        if (!sentSignup && session?.jwt?.isNewUser) {
            conversionSignUp({ signupReason })
            const email = session?.jwt?.email
            if (email) {
                Reflio.signup(email).catch((e) => {
                    notifyError(e, 'reflio')
                })
            }
            localStorage.setItem(signupConversionLocalStorageKey, 'true')
        }
    }, [session])
    return null
}

export function useSignupReason({
    callbackUrl = '',
    signupReason,
}: {
    signupReason?: string
    callbackUrl?: string
}) {
    const { data: session } = useSession()
    const router = useRouter()
    useEffect(() => {
        // new jwt for new user created on this page
        if (session?.jwt.isNewUser && router.query.signupReason) {
            const s = String(router.query.signupReason)
            if (signupReason && s !== signupReason) {
                console.warn(
                    `Skip tracking of signup reason: ${s} !== ${signupReason}`,
                )

                return
            }
            console.log(`Tracking signup reason ${s}`)
            trackUserSignUpReason(s)
        }
    }, [router.query, session, signupReason])
    // const newCb = new URL(callbackUrl, env.NEXT_PUBLIC_URL)
    // newCb.searchParams.set('signupReason', signupReason)
    // return {
    //     callbackUrl: newCb.toString(),
    // }
}
